import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { border, border2, contactbg, mobileContactbg } from 'images'
import RestaurantDetails from './RestaurantDetails'
import Hours from './Hours'
import Map from './Map'
import SocialMedia from './SocialMedia'
import DownloadApp from './DownloadApp'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <Map />
          <CFView
            mt="-5px"
            image={`url(${mobileContactbg}) top / cover no-repeat`}
            bg="rgba(0,0,0,0.8)"
          >
            <CFImage src={border2} w="100vw" alt="About" />
            <CFView column center w="100%" pv="30px">
              <CFView w="100%" column center>
                <RestaurantDetails />
                <Hours />
                <SocialMedia />
              </CFView>
            </CFView>
            <CFImage src={border2} w="100vw" alt="About" />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center>
          <Map />
          <CFView
            column
            center
            w="100%"
            image={`url(${contactbg}) top / cover no-repeat`}
            bg="rgba(0,0,0,0.8)"
            maxWidth="1400px"
            mt="-5px"
          >
            <CFImage src={border} w="100%" alt="About" />
            <CFView w="100%" pv="50px" row center>
              <CFView w="100%" row justifyAround maxWidth="1200px">
                <DownloadApp />
                <CFView column>
                  <RestaurantDetails />
                  <SocialMedia />
                </CFView>
                <Hours />
              </CFView>
            </CFView>
            <CFImage src={border} w="100%" alt="About" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
