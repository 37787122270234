import React, { Component } from 'react'
import { css } from 'emotion'
import {
  Space,
  CFLink,
  CFImage,
  CFText,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  promotionsHeader,
  mobilePromoHeader,
  homebg,
  border,
  promotion1,
  promotion2,
  promotion3,
  partyMenu,
} from 'images'
import { MEDIA_QUERY } from 'styles'
import Features from './Features'

class Promotions extends Component {
  render() {
    return (
      <CFView w="100%">
        <CFView
          w="100%"
          maxWidth="1400px"
          column
          center
          image={`url(${homebg}) center / 50% repeat`}
        >
          <Features />
          <div className={styles.innerContainer}>
            <img
              src={promotionsHeader}
              className={styles.promoHeaderDesktop}
              alt="Promotion Header"
            />
            <img
              src={mobilePromoHeader}
              className={styles.promoHeaderMobile}
              alt="Promotion Header"
            />
            <CFText className={styles.headerContent}>
              Check out some of our amazing offers! The time has come to try
              some delicious Chinese Food.
            </CFText>
            <div className={styles.row}>
              <div className={styles.promoContainer}>
                <div className={styles.promo}>
                  <CFLink
                    target="_black"
                    href="https://order.codefusion.tech/restaurants/JmN79DKqrfU35CEl45Ar/locations/RnXRxe6uNgVMI41bZDn0"
                    className={styles.image}
                  >
                    <img
                      className={styles.imageLink}
                      src={promotion1}
                      alt="Promo 1"
                    />
                  </CFLink>
                  <Space h2 />
                  <CFText
                    className={styles.promoHeader}
                    content
                    h3
                    center
                    xBold
                  >
                    ONLINE REWARDS
                  </CFText>
                  <Space h1 />
                  <CFText className={styles.promoContent}>
                    Receive points on every online order. Redeem exclusive
                    rewards and discounts.
                  </CFText>
                </div>
              </div>
              <div className={styles.promoContainer}>
                <div className={styles.promo}>
                  <img
                    className={styles.image}
                    src={promotion2}
                    alt="Promo 2"
                  />
                  <Space h2 />
                  <CFText
                    className={styles.promoHeader}
                    content
                    h3
                    center
                    xBold
                  >
                    IN-STORE DISCOUNTS
                  </CFText>
                  <Space h1 />
                  <CFText className={styles.promoContent}>
                    Call us at (604) 929-3000 to order and receive 10% off on
                    pick-up order over $40!
                  </CFText>
                </div>
              </div>
              <div className={styles.promoContainer}>
                <div className={styles.promo}>
                  <CFLink
                    href={partyMenu}
                    className={styles.image}
                    target="_blank"
                  >
                    <img
                      className={styles.imageLink}
                      src={promotion3}
                      alt="Promo 3"
                    />
                  </CFLink>
                  <Space h2 />
                  <CFText
                    className={styles.promoHeader}
                    content
                    h3
                    center
                    xBold
                  >
                    PARTY MENU
                  </CFText>
                  <Space h1 />
                  <CFText className={styles.promoContent}>
                    Order from our party menu! One tray contains a single dish
                    and serves about 3-4 people.
                  </CFText>
                </div>
              </div>
            </div>
          </div>
        </CFView>
      </CFView>
    )
  }
}
const styles = {
  innerContainer: css({
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '140rem',
    [MEDIA_QUERY.MD]: {
      overflowX: 'auto',
    },
  }),
  row: css({
    display: 'flex',
    padding: '0 14rem 0 14rem',
    justifyContent: 'space-between',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  promoContainer: css({
    width: '32%',
    [MEDIA_QUERY.MD]: {
      minWidth: '90vw',
    },
  }),
  section: css({
    paddingBottom: '5rem',
    objectFit: 'contain',
    width: '100%',
    maxWidth: '140rem',
  }),
  promoHeaderDesktop: css({
    width: '100%',
    display: 'block',
    marginBottom: '40px',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  promoHeaderMobile: css({
    display: 'none',
    [MEDIA_QUERY.MD]: {
      width: '100%',
      display: 'block',
      marginBottom: '25px',
    },
  }),
  promo: css({
    paddingBottom: '4rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      paddingBottom: '10px',
    },
  }),
  promoHeader: css({
    fontFamily: 'raleway',
    fontWeight: 'bold',
    fontSize: '2.7rem',
    color: 'black',
  }),
  promoContent: css({
    width: '90%',
    margin: '0 1.5rem',
    fontSize: '2rem',
    textAlign: 'center',
    paddingBottom: '2rem',
    color: 'black',
  }),
  header: css({
    fontFamily: 'raleway',
    fontWeight: '900',
    fontSize: 42,
  }),
  headerContent: css({
    fontSize: 24,
    textAlign: 'center',
    margin: '0 auto',
    padding: '0 20% 6rem 20%',
    [MEDIA_QUERY.MD]: {
      padding: '0 4rem 2rem 4rem',
    },
  }),
  image: css({
    width: '90%',
    [MEDIA_QUERY.MD]: {
      width: '80%',
    },
  }),
  imageLink: css({
    width: '100%',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  priceText: css({
    fontFamily: 'georgia',
    fontWeight: 'bold',
    color: '#957B60',
    fontSize: '3rem',
  }),
}

export default Promotions
