import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileOrderButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
        >
          <Header />
          <CFView textCenter column center ph="5%" w="100%">
            <CFView column center mb="15px">
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="5px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          center
          justifyBetween
        >
          <Header />
          <CFView row center w="100%" maxWidth="1200px" pb="25px" ph="10px">
            <CFView row justifyStart mr="20px" textCenter>
              <CFText color="white" style={{ fontSize: 35 }} raleway xBold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center>
              <CFView pt="10px" mr="10px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView pt="10px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
