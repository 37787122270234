import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mb="13px" mr="25px" column center>
          <CFView h3 futura bold color="#C60000" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white>
            <CFView h4 mb="15px" textCenter maxWidth="75vw">
              One More Szechuanis now taking online orders. Order through our
              website or download our app in your app store! Collect points and
              redeem fantastic rewards!
            </CFView>
            <CFView row center>
              <CFView mr="10px">
                <CFLink href="https://apps.apple.com/us/app/one-more-szechuan/id1480078212">
                  <CFImage
                    h="40px"
                    src={appStore}
                    alt="One More SzechuanApp Store"
                  />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.szechuan">
                <CFImage
                  h="40px"
                  src={googlePlay}
                  alt="One More SzechuanGoogle Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px" minWidth="250px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white>
            <CFView maxWidth="260px" fontSize="16px" mb="20px">
              One More Szechuanis now taking online orders. Order through our
              website or download our app in your app store! Collect points and
              redeem fantastic rewards!
            </CFView>
            <CFView row justifyStart alignCenter>
              <CFView mr="10px">
                <CFLink href="https://apps.apple.com/us/app/one-more-szechuan/id1480078212">
                  <CFImage
                    h="37px"
                    mt="5px"
                    src={appStore}
                    alt="One More SzechuanApp Store"
                    hover
                  />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.szechuan">
                <CFImage
                  h="37px"
                  mt="5px"
                  src={googlePlay}
                  alt="One More SzechuanGoogle Play"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
