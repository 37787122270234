import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import {
  border,
  border2,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView column center>
      <MobileScreen>
        <CFView w="100%">
          <CFImage src={border2} alt="About" w="100vw" />
          <CFView mt="35px" w="100vw">
            <Slider {...{ ...settings, slidesToShow: 2 }}>
              <CFImage
                src={feature1}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature2}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature3}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature4}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature5}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature6}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature7}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
              <CFImage
                src={feature8}
                alt="Features"
                style={{ objectFit: 'contain' }}
                ph="5px"
                pv="10px"
              />
            </Slider>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={border} alt="Border" w="100%" />
        <CFView maxWidth="1200px" mt="35px">
          <Slider {...{ ...settings, slidesToShow: 5 }}>
            <CFImage
              src={feature1}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature2}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature3}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature4}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature5}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature6}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature7}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
            <CFImage
              src={feature8}
              alt="Features"
              style={{ objectFit: 'contain' }}
              ph="5px"
              pv="10px"
            />
          </Slider>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
