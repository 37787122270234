import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, homebg } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="One More SzechuanAbout" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          image={`url(${homebg}) center / 50% repeat`}
          row
          justifyStart
          alignCenter
          maxWidth="1400px"
          m="0 auto"
        >
          <CFImage src={about} w="100%" alt="One More SzechuanAbout" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
