export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562131054/Shaolin/foodGallery.jpg'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cflogo.png'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const email =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/email.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/logo.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/hero.jpg'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/background.jpg'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/promotions.png'
export const border =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/border.png'
export const border2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630203873/onemoreszechuan/border2.png'
export const gallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568755510/onemoreszechuan/gallery.png'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/contactbg.jpg'
export const logoSmall =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/logoSmall.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/rewardArrowRight.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/redeemButton.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/rewardDefault.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/rewardbg.jpg'
export const promotion1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568751898/onemoreszechuan/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568751898/onemoreszechuan/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568751898/onemoreszechuan/promotion3.png'
export const promotionsHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568751898/onemoreszechuan/promotionsHeader.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630202969/onemoreszechuan/About_Us.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630202471/onemoreszechuan/locationMap2.jpg'
export const feature1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature1.png'
export const feature2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature2.png'
export const feature3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature3.png'
export const feature4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature4.png'
export const feature5 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature5.png'
export const feature6 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature6.png'
export const feature7 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature7.png'
export const feature8 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1569029128/onemoreszechuan/feature8.png'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630216163/onemoreszechuan/mobileAbout.jpg'
export const mobileLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630203219/onemoreszechuan/mobileLogo.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/mobileContactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630215561/onemoreszechuan/mobileGallery.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630203674/onemoreszechuan/mobileMap.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1605900175/Applause/mobilePromotion.png'
export const mobilePromoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1630216309/onemoreszechuan/mobilePromoHeader.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/downloadButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1568609079/onemoreszechuan/orderButton.png'
// export const menu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1631752603/onemoreszechuan/Menu_Takeout.pdf'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'

export { default as menu } from './oneMoreMenu.pdf'
export { default as partyMenu } from './partyMenu.pdf'
