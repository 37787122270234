import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { gallery, mobileGallery, homebg } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          maxWidth="1400px"
          image={`url(${homebg}) center / 50% repeat`}
        >
          <CFImage
            src={gallery}
            w="100%"
            alt="Food Gallery"
            m="0 auto"
            pv="50px"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
